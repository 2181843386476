$(document).ready(function() {
  $('.js-submit-sb-payment').on('click', function() {
    $(this).off('click', onclick).on('click', false);

    const service = $(this).data('service');
    // NOTE: 月額課金時は決済手段を考慮する必要がある
    var pay_method = '';
    if (service == 'month' || service == 'set_half_year' || service == 'set_year') {
      pay_method = $(this).data('pay-method');
    }

    submitForm($(this).data('url'), service, pay_method);
    return false;
  });

  const submitForm = (url, service, pay_method) => {
    $.ajax({
      type: 'POST',
      url: url,
      data: { _method: 'PUT', service: service, pay_method: pay_method },
      dataType: 'json',
      cache: false,
      timeout: 10000
    }).done((data) => {
      const $form = $('#form-sb-payment');
      $form.attr('accept-charset', 'Shift_JIS');
      $form.attr('action', data.end_point);

      $.each(data.purchase, (key, value) => {
        if (value === '') {
          return true;
        }

        // 明細対応
        if (key === 'dtls') {
          value.forEach(val => $.each(val, (keykey, valval) => $('<input/>', { type: 'hidden', name: keykey, value: valval }).appendTo($form)))
        }

        $('<input/>', { type: 'hidden', name: key, value: value }).appendTo($form);
      });

      $form.submit();
    }).fail(() => {
      window.location.href = url + '/error';
    });
  }
});

// NOTE: Safariのヒストリーバック対策
window.onpageshow = (event) => {
  if (event.persisted && $('.js-submit-sb-payment').length > 0) {
    window.location.reload();
  }
};

