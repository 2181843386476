$(document).ready(function() {
  let planSelectButtons = $('.js-plan-select-button');
  let planBubble = $('.js-plan-bubble');
  let planTermSelect = $('.js-plan-term-select-list');
  let planTermSelectButtons = $('.js-plan-term-select-button');
  let planContent = $('.js-plan-select-content');
  var plan = planSelectButtons.length ? null : 'singlePayment';
  var planTerm = 'halfYear';

  function switchPlanTermButtons(term) {
    planTermSelectButtons.each(function(index, element){
      $(element).removeClass('is-active');

      if ($(element).data('term') == term) {
        $(element).addClass('is-active');
      }
    });
  }

  function switchPlanContents(plan, term) {
    planContent.each(function(index, element){
      $(element).removeClass('is-show');

      if (plan == 'monthly') {
        planTermSelect.hide();

        if ($(element).data('plan') == plan) {
          $(element).addClass('is-show');
        }
      } else if (plan == 'singlePayment') {
        planTermSelect.show();

        if ($(element).data('plan') == plan && $(element).data('term') == term) {
          $(element).addClass('is-show');
          planTerm = $(element).data('term');
        }
      } else {
        planBubble.hide();
        planTermSelect.hide();
      }
    });
  }

  switchPlanContents(plan, 'halfYear');
  switchPlanTermButtons('halfYear');

  // プラン選択
  $('.js-plan-select-button').on('click', function() {
    planBubble.show();
    planSelectButtons.removeClass('is-active');
    $(this).addClass('is-active');

    // プラン選択ボタン下の吹き出しの三角形
    const index = planSelectButtons.index(this);
    $('.js-plan-select-tail').removeClass('is-show').eq(index).addClass('is-show');

    switchPlanContents($(this).data('plan'), planTerm);
  });

  // 一括プランの期間選択
  $('.js-plan-term-select-button').on('click', function() {
    var term = $(this).data('term');
    switchPlanTermButtons(term);
    switchPlanContents('singlePayment', term);
  });
});
